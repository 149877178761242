<template>
    <div class="ui centered stackable grid file-upload-container" :class="uploadStatusClass" style="justify-content: start;">
        <div class="row" style="margin-bottom: -10px;">
            <div class="five wide column "> 
                <div class="file-to-upload">
                    {{ fileName }}
                </div>
                <p class="messageSmallScreen" :class=" uploadStatus === 'completed' ? 'text-blue text-bold messageComplete':'text-blue text-bold messageNotComplete'"
                    v-if="message && uploadStatus !== 'completed'">
                    <small>
                        * {{message}}
                    </small>
                </p>
            </div>

            <div class="six wide computer four wide mobile column file-progress">
                <span v-if="uploadStatus === 'waiting'"> Veuillez selectionner le fichier demandé </span>
                <span v-else-if="uploadStatus === 'inProgress' " > Téléchargement en cours </span>
                <span v-else> Téléchargement complet </span>
            </div>


            <div class="three wide centered column file-upload" v-if="uploadStatus === 'waiting'">
                <label :for="fileType">Parcourir ...</label>
                <input type="file" ref="fileUpload" accept="application/pdf" name="cine" :id="fileType" @change="uploadFile">
            </div>

            <div class="three wide column" v-if="removableFile">
                <div class="ui animated red button file-upload" v-if="uploadStatus === 'completed'" @click="confirmRemoveFile">
                    <div class="visible content">Supprimer</div>
                    <div class="hidden content">
                        <i class="right x icon"></i>
                    </div>
                </div>
            </div>

        </div>
        <p class="messageBigScreen" :class=" uploadStatus === 'completed' ? 'text-blue text-bold messageComplete':'text-blue text-bold messageNotComplete'"
           v-if="message && uploadStatus !== 'completed'">
            <small>
                * {{message}}
            </small>
        </p>

        <div class="ui divider custom-divided" style="width: 100%"></div>

    </div>
</template>

<script>
    export default {
        name: "uploadItem",
        props: {
            fileName: String,
            fileType: String,
            message : String,
            removableFile: {
                type: Boolean,
                default: false
            },
            reinscription:{
                type: Boolean,
                default: false
            }
        },
        data(){
            return {
                isBusy: false,
                uploadStatus: "waiting",
                uploadStatusClass: "",
                uploadedFile: null
            }
        },
        computed: {
            current_user() {
                return this.$store.state.current_user
            },
            dossier() {
                return this.$store.getters.dossier
            }
        },
        methods: {
            uploadFile(event){
                console.log("@@@@@@@@@@@@@ fileUpload" )
                if (this.isBusy) return;

                this.isBusy = true;

                let formData = new FormData()
                let files = event.target.files;

                if (!files) {
                    this.isBusy = false;
                    return;
                }

                if(files[0].type !== 'application/pdf'){
                  this.isBusy = false;
                  this.$toast.error("Format du fichier doit être PDF")
                    this.$refs.fileUpload.value = null;
                  return;
                }

                if (files[0].size > 1024 * 1024 * 10) {
                    this.$toast.warning('La taille du fichier doit être inférieur à 10MB');
                    this.isBusy = false;
                    $("#selected_file").val('');
                    return;
                }

                this.uploadStatus = "inProgress"
                this.uploadStatusClass = "loading"

                formData.append("file", files[0])
                formData.append("type", this.fileType)
                formData.append("labo", this.current_user.labo._id)
                if(this.reinscription || !_.isEmpty(this.dossier.reinscription))
                    formData.append("reinscription_annee", this.dossier?.etude?.current_step?.label || 'hehe')

                this.$store.uploadFile(this.$SHARED.services.upload, formData).then(data => {

                    this.uploadStatus = "completed"
                    this.uploadStatusClass = "complete"

                    this.$emit("uploadCompleted", data[0])
                    this.uploadedFile = data[0]

                }, () =>{
                    this.$toast.error("")
                    this.uploadStatus = "waiting"
                    this.uploadStatusClass = ""

                }).finally(()=> {
                    this.isBusy = false
                })

            },
            confirmRemoveFile(){
                this.$modal.show("dialog", {
                    title: 'Confirmation',
                    text: 'voulez vous vraiment supprimer ce fichier?',
                    buttons: [
                        {
                            title: "Oui",
                            handler: () => {
                                this.removeFile()
                                this.$modal.hide("dialog")
                            }
                        },
                        {
                            title: "Non",
                            handler: () => this.$modal.hide("dialog")
                        }
                    ]
                })
            },
            removeFile(){
                console.log("removeFile")
                if (this.isBusy || !this.uploadedFile)
                    return;

                this.isBusy = true;
                const splitLink = this.uploadedFile.path.split("/")
                const data = {
                    fileName: splitLink[splitLink.length -1],
                    labo: this.current_user.labo
                }
                this.$store.dispatchAsync(this.$SHARED.services.deleteFile, data).then(() => {
                    this.$emit("fileRemoved", this.uploadedFile.path)
                    this.uploadStatus = "waiting"
                    this.uploadStatusClass = ""
                    this.uploadedFile = null

                }).finally(()=> {
                    this.isBusy = false
                })

            }
        }
    }
</script>

<style scoped>

    .file-to-upload {
        color: var(--main-color-blue);
        font-weight: 500;
        border: 1px solid var(--main-color-blue);
        border-radius: 0.3em;
        padding: 0.5em 1em;
        overflow-wrap: break-word;
        margin: 0em 1em 0.5em 1em;
    }

    .file-to-upload {
        color: var(--main-color-blue);
        font-weight: 500;
        border: 1px solid var(--main-color-blue);
        border-radius: 0.3em;
        padding: 0.5em 1em;
        margin: 0em 1em 0.5em 1em;

    }

    .file-upload-container{
        margin-bottom: 16px;
    }

    .file-upload-container.complete .file-to-upload {
        color: #FFF;
        background-color: var(--main-color-green);
        border-color: var(--main-color-green);
    }

    .file-upload-container.loading .file-to-upload {
        color: var(--main-color-orange);
        border-color: var(--main-color-orange);
    }

    .file-progress {
        color: rgba(17, 62, 150, 0.49);
        font-weight: 500;
        background: #EEF1F7;
        border-radius: 5px;
        text-align: center;
        /*min-width: 400px;*/
        padding: 0.5em 2em;
    }

    .file-upload-container.complete .file-progress {
        color: #fff;
        background-color: var(--main-color-green);

    }

    .file-upload-container.loading .file-progress {
        color: #fff;
        background-color: var(--main-color-orange);

    }

    .file-upload {
        margin-left: 1em;
    }

    .file-upload label {
        border-radius: 5px;
        color: #fff;
        background-color: var(--main-color-blue);
        display: inline-block;
        padding: 0.5em 1em;
        cursor: pointer;
    }

    .file-upload input[type=file] {
        display: none;
    }

    .messageComplete {
        margin-left: 18%;
        font-size: smaller;
    }
    .messageNotComplete {
        margin-left: 8%;
        font-size: smaller;
    }
    .messageBigScreen {
        display: block ;
    }
    .messageSmallScreen {
        display: none;
    }

    .custom-divided {
        display: none;
        margin: 0;
        padding: 0
    }

    @media (max-width:768px){
        .messageBigScreen{
            display: none;
        }
        .messageSmallScreen{
            display: block;
        }

        .custom-divided {
            display: block !important;
        }
    }

</style>
